<!-- eslint-disable vue/html-self-closing -->
<template>
  <div class="">
    <BoxContainerLoading :loading="loading" />
    <!-- use componetn -->
    <FormUpdateModal ref="FormUpdateModal" @reload="queryData()" />
    <!-- end use component -->

    <!-- modal upload image -->
    <b-modal
      ref="refModalChangeImage"
      title="เปลี่ยนรูป"
      hideHeaderClose
      ok-title="เปลี่ยน"
      cancel-title="ยกเลิก"
      no-close-on-esc
      no-close-on-backdrop
      @cancel="resetFormUploadChangeImage"
      @ok="updateOneImage"
    >
      <form-upload-change-image ref="refFormUploadChangeImage" :ruleImageDimension="ruleImageDimension" />
    </b-modal>

    <div v-if="item && !loading">
      <b-card no-body class="p-50 mb-2">
        <tab-translations-widget @onChange="onTabTranslationsChange" />
      </b-card>
      <h3 class="mb-2">วอลเปเปอร์หลัก</h3>
      <b-row>
        <b-col cols="12" md="4">
          <div class="main-image-preview">
            <img :src="item.image_thumbnail_preview || item.image_preview" :alt="item.name" />
          </div>
        </b-col>
        <b-col cols="12" md="8" class="mt-1 mt-md-0">
          <b-card class="h-100">
            <div>
              <div class="header-main-image">
                <div>
                  <h3 class="m-0">{{ item.title_translations[keyTranslation] || 'พบข้อผิดพลาด' }}</h3>
                  <small class="text-muted">วันที่สร้าง {{ gFormatDate(item.created_at, true) }}</small>
                </div>
                <b-badge :variant="csFormat.formatVariantStatusBadge(item.approved_status)">{{
                  csFormat.formatStatusBadge(item.approved_status)
                }}</b-badge>
              </div>
              <div v-if="item.approved_status === 'reject'">
                <b-alert variant="danger" :show="item.approved_status === 'reject'" class="mb-1">
                  <div class="alert-body">
                    <feather-icon icon="InfoIcon" class="mr-50" />
                    {{ item.image_timeline[0].note }}
                    <div class="mt-50">
                      <b-row cols="1" cols-md="2">
                        <b-col v-for="(img, index) in item.image_timeline[0].image_datas" :key="index">
                          <box-image-preview-widget :src="img.outside_url" />
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-alert>
              </div>
              <div class="mt-1">
                <h5>รายละเอียดรูป</h5>
                <!-- prettier-ignore -->
                <pre class="custom-pre-text-details ml-2">{{ item.details_translations[keyTranslation] || 'พบข้อผิดพลาด' }}</pre>
              </div>
              <div class="mt-1">
                <h5>เงื่อนไขการใช้วอลเปเปอร์ฟรี</h5>
                <p class="ml-2">
                  {{ hintModalFreeImage }}
                </p>
              </div>
              <div v-if="$store.getters['AuthStore/_isCanManagementRedeem']" class="mt-1">
                <h5>รูปแบบการขาย</h5>
                <p class="ml-2">
                  {{ cDisplayHideInStore }}
                </p>
              </div>
              <b-row cols="1" cols-md="2">
                <b-col>
                  <div>
                    <h5>ขนาดรูป <small class="text-muted">( width x height )</small></h5>
                    <p class="ml-2">{{ dimensionMainImage }}</p>
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <h5>จำนวนรูปทั้งหมด</h5>
                    <p class="ml-2">{{ item.topic_image_list_datas.length }} รูป</p>
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <h5>หมวดหมู่ราศี</h5>
                    <p class="ml-2">{{ zodiacMainImage }}</p>
                  </div>
                </b-col>
                <b-col>
                  <h5>หมวดหมู่วอลเปเปอร์</h5>
                  <div class="custom-container-wallpaper-tags ml-2 mb-1">
                    <b-badge v-for="tag in tagsList" :key="tag.id" variant="primary">
                      {{ tag.tag_name }}
                    </b-badge>
                    <p v-if="tagsList.length <= 0">ไม่ได้เลือกหมวดหมู่</p>
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <h5>รูปแบบฟ้อนต์</h5>
                    <ul>
                      <li>ฟ้อน TH : {{ nameFontTH }}</li>
                      <li>ฟ้อน EN : {{ nameFontEN }}</li>
                    </ul>
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <h5>ลิงค์โซเชียล</h5>
                    <ul>
                      <li>Youtube : {{ renderOptionalText(mainItemData.youtube_link) }}</li>
                      <li>เนื้อหาเพิ่มเติมของวอลเปเปอร์ : {{ renderOptionalText(mainItemData.blog_link) }}</li>
                    </ul>
                  </div>
                </b-col>
              </b-row>

              <div class="mt-1">
                <b-row cols="1" cols-md="2">
                  <b-col>
                    <b-card border-variant="primary" bg-variant="transparent">
                      <h6 class="m-0 text-center">จำนวนดาวน์โหลด {{ totalDownloadImage }} ครั้ง</h6>
                    </b-card>
                  </b-col>
                  <b-col>
                    <b-card border-variant="warning" bg-variant="transparent">
                      <h6 class="m-0 text-center">เรทติ้ง {{ totalRattingImage }}</h6>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="btn-group-edit-or-delete">
              <b-button variant="primary" :disabled="item.approved_status === 'checking'" @click="onClickAllEdit">
                แก้ไข
              </b-button>
              <b-button
                v-if="
                  $store.getters['AuthStore/_isCanManagementRedeem'] &&
                    (item.approved_status === 'approved' || item.approved_status === 'approve')
                "
                variant="secondary"
                block
                @click="clickCreateRedeem"
              >
                สร้างแคมเปญ Redeem
              </b-button>
              <b-button variant="dark" @click="onClickMoreTimeline">ประวัติรีวิว</b-button>
              <b-button variant="danger" :disabled="item.approved_status === 'checking'" @click="onClickAllDelete">
                ลบทั้งหมด
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <!-- <pre>wallpaper id: {{ $route.params.id }}</pre> -->
      <!-- <pre>{{ JSON.stringify(mainItemData, null, 2) }}</pre> -->
      <!-- <pre>{{ JSON.stringify(item, null, 2) }}</pre> -->
      <h3 class="my-2">วอลเปเปอร์ทั้งหมด</h3>
      <b-row cols="1" cols-md="2" cols-xl="3">
        <b-col v-for="(image, index) in item.topic_image_list_datas" :key="image.id">
          <b-overlay :show="image.id === currentImageSelectChange" rounded="sm">
            <b-card
              :img-src="image.image_thumbnail_url_preview || image.image_preview"
              img-top
              img-alt="card img"
              class="mb-3"
              body-class="p-0"
            >
              <b-button
                v-if="!currentImageSelectChange && item.topic_image_list_datas.length > 1"
                :disabled="item.approved_status === 'checking'"
                variant="danger"
                size="sm"
                class="btn-delete"
                @click="onClickDelete(image, index, item.topic_image_list_datas)"
              >
                ลบ
              </b-button>
              <b-button
                :disabled="item.approved_status === 'checking'"
                variant="primary"
                class="m-50"
                @click="showModalChangeImage(image, index)"
              >
                เปลี่ยนรูป
              </b-button>
              <b-button variant="flat-primary" class="mx-50" @click="updateMainImagePreviewInStore(image)">
                ตั้งเป็นวอลเปเปอร์หลักหน้าร้านค้า
              </b-button>
              <div class="p-2">
                <h5>สถานะการขาย</h5>
                <b-card-text class="ml-1">
                  <b-badge :variant="image.disable ? 'danger' : 'success'">{{
                    image.disable ? 'ปิดการขาย' : 'เปิดการขาย'
                  }}</b-badge>
                </b-card-text>
                <h5>ชื่อรูปนี้</h5>
                <b-card-text class="ml-1">{{ image.title_translations[keyTranslation] || 'ไม่พบข้อมูล' }}</b-card-text>
                <h5>รายละเอียดรูปนี้</h5>
                <!-- prettier-ignore -->
                <pre class="custom-pre-text-details ml-1">{{ image.details_translations[keyTranslation] || 'ไม่พบข้อมูล' }}</pre>
                <h5>ราคา</h5>
                <b-card-text class="ml-1">{{ image.price }} บาท</b-card-text>
                <h5>สีลายน้ำ</h5>
                <b-card-text class="ml-1">
                  {{ watermarkColorText(image) }}
                </b-card-text>
                <h5>สีตัวอักษร</h5>
                <b-card-text class="ml-1">
                  <div class="show-color-container">
                    <div class="box" :style="{ 'background-color': image.color_code_data_id }"></div>
                    <p>{{ image.color_code_data_id }}</p>
                  </div>
                </b-card-text>
                <h5>ผูกดวงประจำวัน</h5>
                <b-card-text class="ml-1">
                  {{ image.name || 'ไม่ได้เลือกผูกดวง' }}
                </b-card-text>
                <b-row cols="1">
                  <b-col>
                    <b-card border-variant="primary" bg-variant="transparent" class="mb-1">
                      <h6 class="m-0 text-center">จำนวนดาวน์โหลด {{ image.download_total || 0 }} ครั้ง</h6>
                    </b-card>
                  </b-col>
                  <b-col>
                    <b-card border-variant="warning" bg-variant="transparent" class="mb-1">
                      <h6 class="m-0 text-center">เรทติ้ง {{ image.avg || 0 }}</h6>
                    </b-card>
                  </b-col>
                </b-row>
                <div v-if="item.approved_status !== 'wait'">
                  <b-button variant="success" block v-if="image.disable" @click="changeWallpaperDisable(true, image)">
                    เปิดการขาย
                  </b-button>
                  <b-button variant="danger" block v-else @click="changeWallpaperDisable(false, image)">
                    ปิดการขาย
                  </b-button>
                </div>

                <b-button
                  v-if="userInfo.role_data_preview === 'admin'"
                  class="mt-50"
                  block
                  variant="flat-secondary"
                  @click="goToCreateWallpaper(image)"
                >
                  สร้างรูป
                </b-button>
              </div>
            </b-card>
          </b-overlay>
          <!-- <pre>{{ JSON.stringify(image, null, 2) }}</pre> -->
          <!-- <pre>{{ JSON.stringify(userInfo, null, 2) }}</pre> -->
        </b-col>
      </b-row>

      <b-modal id="modal-review-transaction" ref="modal-review-transaction" size="lg" hide-footer title="ประวัติรีวิว">
        <TimelineReviewWidget :listData="item.image_timeline" maskNameReview="Admin Ongphra Wallpaper" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapMutations, mapState } from 'vuex'
import TimelineReviewWidget from '@/widgets/TimelineReviewWidget.vue'
import BoxContainerLoading from '@/widgets/BoxContainerLoading.vue'

export default {
  components: {
    FormUpdateModal: () => import('./components/WallpaperManagementDetails/FormUpdateModal.vue'),
    FormUploadChangeImage: () => import('./components/WallpaperManagementDetails/FormUploadChangeImage.vue'),
    ToastificationContent,
    TimelineReviewWidget,
    BoxContainerLoading,
  },
  data() {
    return {
      item: null,
      loading: false,
      ruleImageDimension: null,
      currentImageSelectChange: null,
      keyTranslation: 'th',
    }
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),
    mainItemData() {
      let data = null
      try {
        data = JSON.parse(this.item.json_data)
      } catch (error) {
        // console.log('error', error)
      }
      return data
    },
    dimensionMainImage() {
      try {
        const dataJson = JSON.parse(this.item.json_data)
        if (!this.item || !dataJson.file) return 'ไม่พบข้อมูล'

        const { naturalHeight, naturalWidth } = dataJson.file
        const height = naturalHeight || 0
        const width = naturalWidth || 0
        return `${width} x ${height}`
      } catch (error) {
        // console.log('error', error)
        return 'พบข้อผิดพลาด'
      }
    },
    totalDownloadImage() {
      if (!this.item) return '0'
      let total = 0
      this.item.topic_image_list_datas.forEach(val => {
        total += val.download_total
      })
      return `${total}`
    },
    totalRattingImage() {
      if (!this.item) return '0.00'

      return `${Number(this.item.all_avg ?? 0).toFixed(2)}`
    },
    zodiacMainImage() {
      if (!this.mainItemData) return 'ไม่พบข้อมูล'
      if (!this.item.has_zodiac) return 'ไม่ได้เลือกราศี'

      return this.mainItemData.zodiac.name
    },
    tagsList() {
      if (!this.item) return []
      if (!this.item.wallpaper_tags) return []
      return this.item.wallpaper_tags
    },
    nameFontTH() {
      if (!this.mainItemData.optionsFont.TH.name) return 'ไม่ได้เลือกฟ้อน'
      const { family } = this.mainItemData.optionsFont.TH.name
      if (family) {
        return family
      }
      return 'ไม่ได้เลือกฟ้อน'
    },
    nameFontEN() {
      if (!this.mainItemData.optionsFont.EN.name) return 'ไม่ได้เลือกฟ้อน'
      const { family } = this.mainItemData.optionsFont.EN.name
      if (family) {
        return family
      }
      return 'ไม่ได้เลือกฟ้อน'
    },
    hintModalFreeImage() {
      return this.item?.hint_modal_free_image_translations[this.keyTranslation] ?? 'ยังไม่ได้ระบุ'
    },
    cDisplayHideInStore() {
      return this.item?.hide_in_store
        ? 'ไม่แสดงในแอปและเว็บ (สามารถใช้ Redeem เพื่อสร้างรูปวอลเปเปอร์ได้เท่านั้น)'
        : 'แสดงในแอปและเว็บ (สามารถใช้ Redeem หรือ Coin เพื่อสร้างรูปวอลเปเปอร์ได้)'
    },
  },
  methods: {
    ...mapMutations('wallpapers', ['SET_DATA_EDIT_IMAGE']),
    async onClickAllEdit() {
      this.SET_DATA_EDIT_IMAGE({ data: this.item })
      return
      // this.gOpenPageLoading()
      const { id } = this.item
      // const res = await this.api.get(`api/admin/admin-topic-images/check-status/${id}`)
      // console.log('[RES]: statusReview', res)
      // this.gClosePageLoading()
      // return
      if (res === 'wait') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'รูปอยู่ระหว่างรออนุมัติ',
            icon: 'BellIcon',
            text: 'ไม่สามารถแก้ไขได้รูปที่อยู่ระหว่างรออนุมัติได้',
            variant: 'danger',
          },
        })
      } else {
        this.SET_DATA_EDIT_IMAGE({ data: this.item })
      }
    },
    async onClickDelete(image, index, topics) {
      const resp = await this.api.deleteV2(`/api/admin/admin-topic-image-lists/${image.id}`, this)
      // console.log('onClickDelete', resp)
      if (!resp) return

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
        await this.queryData()
        return
      }

      topics.splice(index, 1)
    },
    async onClickAllDelete() {
      // const resp = await this.api.deleteV2(`/api/admin/admin-topic-images/${this.item.id}`, this)
      // if (!resp) return
      // this.$router.go(-1)

      let url = 'api/partner/manage-images/image-data/destroy'

      const { role_data_preview } = this.userInfo

      // eslint-disable-next-line camelcase
      if (role_data_preview === 'admin') {
        url = '/api/admin/admin-topic-images'
      }

      const resp = await this.api.deleteV2(`${url}/${this.item.id}`, this)
      // console.log('onClickAllDelete', resp)
      if (!resp) return
      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
      }
      this.$router.go(-1)
    },
    onClickMoreTimeline() {
      if (this.item.image_timeline.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ไม่พบประวัติการรีวิว',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$refs['modal-review-transaction'].show()
    },
    async queryData() {
      const { id } = this.$route.params
      const resp = await this.api.get(`api/admin/admin-topic-images/${id}`).catch(() => null)
      // console.log('queryData', resp)

      if (!resp) {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถร้องขอข้อมูลรูปนี้ได้', 'danger')
        this.$router.push({ name: 'wallpapers', replace: true })
        return
      }

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
        this.$router.push({ name: 'wallpapers', replace: true })
        return
      }

      this.item = resp
    },
    watermarkColorText(item) {
      switch (item?.watermark_color) {
        case 'black':
          return 'สีดำ'
        case 'white':
          return 'สีขาว'
        default:
          return 'สีดำ'
      }
    },
    async callApiToDisableOrOpenWallpaper(changeTo, item) {
      const res = await this.api.post('api/admin/admin-topic-image-lists/disable', {
        id: item.id,
      })
      // console.log('res', res)
    },
    async changeWallpaperDisable(changeTo, item) {
      // สถานะที่จะเปลี่ยน => changeTo
      // console.log('changeTo', changeTo)
      // console.log('item', item)
      let title = 'ยืนยันปิดการขายรูปนี้ ?'

      if (changeTo) {
        title = 'ยืนยันเปิดการขายรูปนี้อีกครั้ง ?'
      }

      // const { isConfirmed } = await this.$swal({
      //   title,
      //   icon: 'warning',
      //   allowOutsideClick: false,
      //   showCancelButton: true,
      //   confirmButtonText: 'ยืนยัน',
      //   cancelButtonText: 'ยกเลิก',
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //     cancelButton: 'btn btn-outline-danger ml-1',
      //   },
      //   buttonsStyling: false,
      // })
      const isConfirmed = await this.gCheckConfirmV1(title)

      if (!isConfirmed) return

      this.loading = true
      await this.callApiToDisableOrOpenWallpaper(changeTo, item)
      await this.queryData()

      this.loading = false
    },
    goToCreateWallpaper(image) {
      const { id } = this.$route.params
      const params = {
        topic_image_data_id: id,
        topic_image_list_data_id: image.id,
        zodiac: this.mainItemData.zodiac,
        previewUrl: image.image_thumbnail_url_preview,
      }
      // console.log('goToCreateWallpaper', params)
      this.$router.push({ name: 'wallpaper-create', params })
    },
    showModalChangeImage(image, index) {
      this.currentImageSelectChange = image.id
      let dimensionObj = null
      try {
        const dataJson = JSON.parse(this.item.json_data)
        if (dataJson.file) {
          const { naturalHeight, naturalWidth } = dataJson.file
          const height = naturalHeight || 0
          const width = naturalWidth || 0
          dimensionObj = { width, height }
        }
      } catch (error) {
        this.currentImageSelectChange = null
        dimensionObj = null
      }
      // console.log('showModalChangeImage', { image, dimensionObj })
      this.ruleImageDimension = dimensionObj
      this.$refs.refModalChangeImage.show()
    },
    resetFormUploadChangeImage() {
      // console.log('resetFormUploadChangeImage')
      this.currentImageSelectChange = null
      this.ruleImageDimension = null
    },
    async updateOneImage() {
      const { file } = this.$refs.refFormUploadChangeImage
      // console.log('file', file)
      if (file && this.currentImageSelectChange) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('id', this.currentImageSelectChange)

        const resp = await this.api.post('api/image-list-data/change/one', formData)
        // console.log('updateOneImage', resp)
        this.resetFormUploadChangeImage()

        if (resp.message) {
          this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
          await this.queryData()
          return
        }

        if (resp?.id) {
          if (this.item.filename.split('.')[0] === this.currentImageSelectChange) {
            await this.queryData()
          } else {
            this.item.topic_image_list_datas = this.item.topic_image_list_datas.map(v => {
              if (v.id === resp.id) {
                return { ...resp }
              }
              return { ...v }
            })
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'เปลี่ยนรูปภาพสำเร็จแล้ว',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'เปลี่ยนรูปภาพไม่สำเร็จแล้ว โปรดลองใหม่อีกครั้ง',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'คุณยังไม่ได้เลือกรูปภาพที่ต้องการเปลี่ยน',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
      this.resetFormUploadChangeImage()
    },
    async updateMainImagePreviewInStore(image) {
      // console.log('updateMainImagePreviewInStore', image)
      this.currentImageSelectChange = image.id
      // await new Promise(r => setTimeout(r, 1000))
      const resp = await this.api.post('api/topic-image/change/default', { topic_image_list_data_id: image.id })
      // console.log('updateMainImagePreviewInStore', resp)
      // this.loading = true
      await this.queryData()
      // this.loading = false
      // this.item.image_thumbnail_preview = image.image_thumbnail_url_preview
      // this.item.image_preview = image.image_preview
      this.currentImageSelectChange = null
    },
    renderOptionalText(text, emptyText = '-') {
      if (text) return text

      return emptyText
    },
    clickCreateRedeem() {
      this.$router.push({ name: 'redeem-management-create', params: { mainWallpaperId: this.item.id } })
    },
    onTabTranslationsChange(values) {
      // console.log('onTabTranslationsChange', values)
      this.keyTranslation = values.local_code
    },
  },
  async created() {
    this.loading = true
    await this.queryData()
    this.loading = false
  },
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
}
.main-image-preview {
  width: 100%;
  height: 100%;
  background-color: #47526f;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.show-color-container {
  display: flex;
  align-items: center;
  gap: 6px;
  p {
    margin: 0;
  }
  .box {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid black;
  }
}
.btn-delete {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 0.5rem;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header-main-image {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.btn-group-edit-or-delete {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
}

.custom-container-wallpaper-tags {
  display: flex;
  gap: 5px;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
